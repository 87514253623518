<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <HelloWorld msg="msg" />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  methods:{
    
  },
  mounted(){
    
  }
}
</script>

<style>
#app {
  background-color: #efefef;
  height: 95vh;
  width: 95vw;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.ml2{
  margin-left: 2vw;
}
.mt1{
  margin-top:1vh;
}
.mt2{
  margin-top: 3vh;
}
.titletext{
  display: inline;font-size: 12px;
  margin-left: 10px;
}
.titletext1{
  display: inline;font-size: 12px;
  margin-right: 10px;
}

.ln{
  display: inline;
}

.imgs{
  max-height: 50vw;
  width: auto;
  height: auto;
}

.tupian{
  margin-left: 20px !important;
  margin-top: 20px !important;
  font-size: 0.8rem !important;
  padding: 10px !important;
}

@media screen and (max-width: 700px) {
  /* .tupian{
    margin-left: 10px !important;
    margin-top: 20px !important;
    font-size: 0.8rem !important;
    padding: 10px !important;
  } */
}
</style>
