<template>
  <!--对话总体-->
  <div>
    <el-container style="width:95vw;height: 98vh;" v-loading="lodo">
      <el-main>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>与{{axioscode}}的链接</span>
            <el-button style="margin-left: 50px;" type="text" @click="clear()">退出</el-button>
          </div>
          <div v-for="o in datas" :key="o">
            <div class="mt2" v-if="o.name==1">
              <div class="ln">
                <el-button type="success" size="mini" style="padding: 7px;"  circle></el-button>
              </div>
              <div class="sub-title titletext">对方</div>
              <div class="text mt1" v-if="o.iftext==0">
                {{o.content }}
              </div>
              <div v-if="o.iftext==1">
                <img class="imgs" :src="o.content" alt="">
              </div>
            </div>
            <div class="mt2" v-if="o.name==0" style="text-align: right;">
              <div class="sub-title titletext1">我</div>
              <div class="ln">
                <el-button type="success" size="mini" style="padding: 7px;"  circle></el-button>
              </div>
              <div class="text mt1" v-if="o.iftext==0">
                {{o.content}}
              </div>
              <div v-if="o.iftext==1">
                <img class="imgs"  :src="o.content" alt="">
              </div>
            </div>
          </div>
        </el-card>
      </el-main>
      <el-footer height="20vh">
        <div class="mt2">
          <el-row >
            <el-col :span="clientWidth">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                v-model="textarea">
              </el-input>
            </el-col>
            <el-col :span="24-clientWidth">
              <el-button @click="uphttp()" style="margin-left: 20px;font-weight: 600;" class="ml2" type="primary">发送</el-button>
              <el-upload
                list-type="picture"
                action=''
                accept=".jpg, .png"
                :limit="1"
                :auto-upload="false"
                :file-list="fileList"
                :on-change="getFile"
                :on-preview="handlePictureCardPreview"
                 :on-remove="handleUploadRemove"
                >
                <el-button  class="tupian"
              @click="uploadimg">图片上传</el-button>
                <!-- <div slot="tip" class="el-upload__tip">只能上传一张jpg/png文件</div> -->
              </el-upload>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
    
    <el-dialog 
      title="建立链接"
      width="80%"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div v-if="dilogindex==0" style="display: flex;justify-content: space-evenly">
        <el-button class="mt2" type="primary" @click="skip(1)"> 接 受 </el-button>
        <el-button class="mt2" type="primary" @click="skip(2)"> 发 送 </el-button>
      </div>
      <div v-if="phone">
        <div v-if="dilogindex==1" style="display: flex" >
          <el-input v-model="contpassword" style="min-width: 100px;" placeholder="请输入接受验证码"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addwebip">确 定</el-button>
          </span>
        </div>
      </div>
      <div v-if="!phone">
        <div v-if="dilogindex==1" style="display: flex" >
          <el-input v-model="contpassword" type="password" placeholder="请输入密码"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addwebip">确 定</el-button>
          </span>
        </div>
        <div v-if="dilogindex==2" style="display: flex" >
          <el-input v-model="axioscode" placeholder="获取的验证码" disabled></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="guanbi">关闭页面</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Stomp from "stompjs";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return{
      lodo:true,
      dialogVisible: false,
      textarea: '',
      textareaimg: '',
      datas:[],
      circleUrl:"../assets/tx1.png",
      squareUrl:"../assets/tx2.png",
      websocket:"",
      websockip:"ws://47.115.200.31:61614",//wss://47.115.200.31 ws://47.115.200.31:61614
      client:{},
      currentSubscribe :"",
      axioscode:"xxx",
      contpassword:"",
      sockStatus:false,//服务器是否链接成功，
      connectNum:0,//服务器重连次数
      dilogindex:1,//对话框页面判断
      phone:false,
      fileList:[],//图片上传
      fileListimg:0,//图片上传
      clientWidth:'',
      clientHeight:''
    }
  },
  mounted(){
    
    var w=document.body.clientWidth || document.documentElement.clientWidth;
    // var h=document.body.clientHeight || document.documentElement.clientHeight;
    if(w<=400){
      this.clientWidth=18
    }else if(w<=500&&w>400){
      this.clientWidth=19
    }else if(w<=600&&w>500){
      this.clientWidth=20
    }else if(w<=700&&w>600){
      this.clientWidth=21
    }else if(w>700){
      this.clientWidth=22
    }
    
    this.datas=[]
    this.datas.push({content:"",name:"0",iftext:"0"})
    // this.addrandomStr();
    // 通过遮罩层关闭图片预览
    document.addEventListener('click',function(e){
        if(e.target.className=="el-image-viewer__mask"){
            let close = document.querySelector(".el-icon-circle-close");
            close.click();
        }
    });
    this.dialogVisible=true;
    this.skip()
    // if(this.client!=null){
      
    //   // 监听接收消息事件
    //   this.client.on('connect', function(){
    //      // 监听接收消息事件
    //     this.client.on('message', (topic, message) => {
    //         console.log('收到来自', topic, '的消息', message.toString())
    //     })
    //   })
    // }
  },
  methods:{
    guanbi(){
      this.dialogVisible=false
    },
    skip(){
      if (typeof window.orientation !== 'undefined') {
        // 当前设备是移动设备 
        this.phone=true
      }else{
        this.phone=false
      }
      // if(this.dilogindex==1){
      //   this.dilogindex++
      // }else{
      //   this.dilogindex--
      // }
    },
    handleClose(done) {
      // this.dialogVisible=false
      // this.addwebip();
      console.log(done);
    },
    addwebip(){
      if(this.contpassword==''){
        var vahs='';
        if(this.phone){
          vahs='请输入验证码';
        }else{
          vahs='请输入密码';
        }
        this.dialogVisible=true;
        this.$message({
          message: vahs,
          type: 'warning',
        })
      }else{
        // this.dialogVisible=false
        this.InitMqtt();
      }
    },
    // 图片上传
    uploadimg(){
      console.log("图片上传");
    },
    clear(){
      this.dialogVisible = true;
      this.dilogindex=1;
      this.axioscode='xxx';
      this.textarea='';
      this.textareaimg='';
      this.contpassword='';
      this.datas=[];
      this.client=[];
      this.datas.push({content:"",name:"0",iftext:"0"});
    },
    //发送
    uphttp(){
      // this.client.on
      this.lodo=true
      var updata={content:"",name:"0",iftext:""};
      if(this.fileListimg==1){
        updata.iftext=1
        updata.content = this.textareaimg;
      }else{updata.iftext=0
        updata.content = this.textarea;
      }
      axios({
        url:"https://chuan-api.azlbaby.com/app/msn/send",
        method:"post",
        data:{
          "randomStr": this.axioscode,
          "content": updata.content
        }
      }).then(res=>{
        if(res.data.result){
          this.textarea='';
          this.textareaimg='';
          this.fileList=[];
          this.fileListimg=0;
          // this.handleUploadRemove();
          this.datas.push(JSON.parse(JSON.stringify(updata)))
          console.log("发送成功");
          console.log(res);
        }else{
          console.log(res);
        }        
      })
    },
    InitMqtt() {
      if(this.phone){
        this.axioscode=this.contpassword
        axios({
          url:"https://chuan-api.azlbaby.com/app/msn/phonelogin",
          method:"post",
          data:{
            "randomStr": this.axioscode
          }
        }).then(res=>{
          if(res.data.result){
            this.dialogVisible=false
            console.log("链接建立成功");
            this.addrandomStr();
          }else{
            // "链接建立错误"+
            console.log(res);
          }
        })(err => {
          // 返回错误信息
          console.log("err")
          console.log(err)
        });
      }else{
        axios({
          url:"https://chuan-api.azlbaby.com/app/msn/pclogin",
          method:"post",
          data:{
            "password": this.contpassword
          }
        }).then(res=>{
          if(res.data.result.isOK){
            this.axioscode=res.data.result.randomStr;
            this.dilogindex++;
            console.log("链接密码获取成功");
            this.addrandomStr();
          }else{
            console.log("链接密码错误");
          }
        })(err => {
          // 返回错误信息
          console.log("err")
          console.log(err)
        });
      }
      
    },
    addrandomStr(){
      var self = this
      var mqttConfig = {
        login: 'admin',
        passcode: 'password',
      }
      
      self.client = Stomp.client(this.websockip)
      var onconnect = function () {
        console.log('服务器连接成功')
        self.sockStatus = true //连接成功！
        // self.datas.push({content:"链接已建立",name:"0",iftext:"0"},{content:"链接已建立",name:"1",iftext:"0"})
        self.lodo=false
        console.log(self.axioscode);  
        // self.uphttp();
        self.client.subscribe('/topic/'+self.axioscode, (msg)=>{
          var datas=msg.body
          var shortName ={content:"",name:"1",iftext:""};
          shortName.content=datas
          self.lodo=false;
          if(self.textarea==datas||self.datas[self.datas.length-1].content==datas){
            console.log("自己输入的");
          }else{
            if(self.textareaimg==datas||self.datas[self.datas.length-1].content==datas){
              console.log("自己输入的");
            }else{
              if(datas.length>500){
                if(datas.substring(0,10)=='data:image'){
                  shortName.iftext=1
                }else{
                  shortName.iftext=0
                }
              }
              self.datas.push(JSON.parse(JSON.stringify(shortName)))
              
              console.log(msg);
            }
            
          }
        })
      }     
      var errorCallback = function (err) {
        console.log(err)
        self.sockStatus = false //连接失败！
        self.$message({
          message: `服务已断开连接， 第${self.connectNum}次开始重新连接！`,
          type: 'warning',
        })
        console.log(`服务已断开连接， 第${self.connectNum}次开始重新连接！`)
        var time = setTimeout(() => {
          self.connectNum++
          self.InitMqtt()
          clearTimeout(time)
        }, 5000)
      }

      this.client.connect(
        mqttConfig.login,
        mqttConfig.passcode,
        onconnect,
        errorCallback
      )
      
      // this.client.on('message', (topic, message) => {
      //     console.log('收到来自', topic, '的消息', message.toString())
      // })
      
      // this.client.subscribe(this.axioscode, function (err) {
      //   if (!err) {
      //       console.log('发送消息：'+'Hello mqtt')
      //       this.client.publish('presence', 'Hello mqtt')
      //   }else{
      //     console.log(err);
      //   }
      // })
    },
    getFile(file, fileList) {
      this.getBase64(file.raw).then(res => {
        console.log(res);
        // const params = res
        // this.proofImage = params
        this.textareaimg=res
        this.fileListimg=1;
      })
      console.log(this.fileList.length+fileList);
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove(file, fileList) {
      // this.proofImage = ''
      this.ruleForm.message_img = ''
      console.log(file+fileList);
    },
    handlePictureCardPreview(file) {
      // console.log(this.proofImage)
      console.log(file);
    },

  }
}
</script>

<style scoped>
  .text{
    font-size: 1rem;
  }
  .mt2{
    margin-top: 2vh;
  }
</style>
